
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import gsap from "gsap";
import {
  TransformWrapper,
  TransformComponent,
} from "react-zoom-pan-pinch";

import './Map.scss';
import MapElement from "./MapElement";

const Map = () => {
    const [displayNewLines, setDisplayNewLines] = useState(null);

    //states for showing dotted lines
    const [displayU1, setDisplayU1] = useState(false);
    const [displayU9, setDisplayU9] = useState(false);
    const [displayU11, setDisplayU11] = useState(false);
    const [displayU14, setDisplayU14] = useState(false);

    const map = document.querySelector('.react-transform-wrapper')

    const getScale = () => {
      if(window.innerWidth<768 && window.innerHeight>500){
        return 2
      }else{
        return .6
      }
    }

    const scale = getScale()

    const handleDisplayNewLines = (resetTransform, zoomToElement) => {
      resetTransform()
      zoomToElement(map, scale)
      if (displayNewLines === true) {
        setDisplayNewLines(false)
        setDisplayU1(false)
        setDisplayU9(false)
        setDisplayU11(false)
        setDisplayU14(false)
        let radiobtn = document.getElementById("showAll");
        radiobtn.checked = true;
      } else {
        setDisplayNewLines(true)
      }
    }

    const comp = useRef(); // reference for toggleInitAnim
    const togglInit = useRef();
    const togglInitBg = useRef();
    const togglInitBg2 = useRef();
    const initToggleAnim = useRef();

    useLayoutEffect(() => {
      const toggleInitArray = [togglInitBg.current, togglInitBg2.current]
      let ctx = gsap.context(() => {
        initToggleAnim.current = gsap.timeline({repeat: -1, paused: true});

        initToggleAnim.current.to(togglInit.current, {
          scale: 0.9,
          duration: 1.2,
          ease: 'bounce.inOut'
        })
        initToggleAnim.current.to(toggleInitArray, {
          opacity: 0,
          scaleX: function(index) {
            return 1.15 + (0.1 * index)
          },
          scaleY: function(index) {
            return 1.3 + (0.1 * index)
          },
          duration: .8,
          stagger: .4,
          ease: 'linear'
        }, "<")
      }, comp);

      return () => ctx.revert(); // cleanup
    })

    useEffect(() => {
      if (displayNewLines === null) {
        initToggleAnim.current.play();
      } else if (displayNewLines === true || displayNewLines === false) {
        initToggleAnim.current.kill();
      }
    }, [displayNewLines])

    //refactor later on
    const handleCompare = (id, resetTransform, zoomToElement) => {
      if (id === 'all') {
          setDisplayU1(false)
          setDisplayU9(false)
          setDisplayU11(false)
          setDisplayU14(false)
          resetTransform()
          zoomToElement(map, scale)
      } else if (id === 'u1') {
        if(!displayU1) {
          setDisplayU1(true)
          setDisplayU9(false)
          setDisplayU11(false)
          setDisplayU14(false)
        }
      } else if (id === 'u9') {
        if(!displayU9) {
          setDisplayU1(false)
          setDisplayU9(true)
          setDisplayU11(false)
          setDisplayU14(false)
        }
      }else if (id === 'u11') {
        if(!displayU11) {
          setDisplayU1(false)
          setDisplayU9(false)
          setDisplayU11(true)
          setDisplayU14(false)
        }
      } else if (id === 'u14') {
        if(!displayU14) {
          setDisplayU1(false)
          setDisplayU9(false)
          setDisplayU11(false)
          setDisplayU14(true)
        }
      }
    }

    return(
      <div className="map">
        <div className="map-wrapper">
          <TransformWrapper
            initialScale={getScale()}
            minScale={getScale()}
            limitToBounds={true}
            smooth={false}
            doubleClick={{'disabled': true}}
            centerOnInit={true}
            initialPositionY={0}
          >
            {({ zoomIn, zoomOut, zoomToElement, resetTransform }) => (
              <React.Fragment>
                <div className="header">

                <div className="controls">
                    <div className="toggle-button">
                      <div className="switch btn-color-mode-switch">
                        <input type="checkbox" name="color_mode" id="color_mode" aria-label="Karte umschalten" value="1" onClick={() => handleDisplayNewLines(resetTransform, zoomToElement)}/>
                        <label ref={togglInit} htmlFor="color_mode" data-on="2024" data-off="2023" className="btn-color-mode-switch-inner"></label>
                        <span ref={togglInitBg} className="init-animation"></span>
                        <span ref={togglInitBg2} className="init-animation"></span>
                      </div>
                    </div>

                    <div className={ displayNewLines ? 'radio-group --open' : 'radio-group'}>
                      <ul>
                        <li>
                          <div className="input-group">
                            <input name="changedLine" id="showAll" type="radio" defaultChecked onClick={() => handleCompare('all', resetTransform, zoomToElement)}/>
                            <span></span>
                            <label htmlFor="showAll">Alle</label>
                          </div>
                        </li>

                        <li>
                          <div className="input-group">
                            <input name="changedLine" type="radio" id="showU1" onClick={() => handleCompare('u1')}/>
                            <span></span>
                            <label htmlFor="showU1">U1</label>
                          </div>
                        </li>

                        <li>
                          <div className="input-group">
                            <input name="changedLine" type="radio" id="showU9" onClick={() => handleCompare('u9')}/>
                            <span></span>
                            <label htmlFor="showU9">U9</label>
                          </div>
                        </li>

                        <li>
                          <div className="input-group">
                            <input name="changedLine" type="radio" id="showU11" onClick={() => handleCompare('u11')}/>
                            <span></span>
                            <label htmlFor="showU11">U11</label>
                          </div>
                        </li>

                        <li>
                          <div className="input-group">
                            <input name="changedLine" type="radio" id="showU14" onClick={() => handleCompare('u14')}/>
                            <span></span>
                            <label htmlFor="showU14">U14</label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="navigation">
                      <div className="zoom">
                        <button className="zoom__btn" onClick={() => zoomIn()}><img src={process.env.PUBLIC_URL + '/icons/zoom-in.svg'} alt="zoom in" /></button>
                        <button className="zoom__btn" onClick={() => zoomOut()}><img src={process.env.PUBLIC_URL + '/icons/zoom-out.svg'} alt="zoom out" /></button>
                      </div>

                      <div className={ (displayU1 || displayU9 || displayU11 || displayU14) ? 'legend --open' : 'legend'}>
                        <ul>
                          <li>
                            <span></span>
                            <p className="new">Neue Linienführung</p>
                          </li>

                          <li>
                            <span className="--old"></span>
                            <p className="old">Alte Linienführung</p>
                          </li>
                        </ul>
                      </div>
                  </div>

                  <a className="logo" href="https://www.vvs.de/" target="_blank" rel="noreferrer">
                    <img src={process.env.PUBLIC_URL + '/vvs-logo.png'} alt='vvs-logo'/>
                  </a>
                </div>

                <TransformComponent className="image-wrapper">
                  <MapElement
                    zoomToElement={zoomToElement}
                    displayNewLines={displayNewLines}
                    displayU1={displayU1}
                    displayU9={displayU9}
                    displayU11={displayU11}
                    displayU14={displayU14}
                    scale={scale}
                  />
                </TransformComponent>

                <footer>
                  <a href="https://www.vvs.de" target="_blank" rel="noreferrer">VVS</a>
                  <a href="https://www.vvs.de/impressum" target="_blank" rel="noreferrer">Impressum</a>
                  <a href="https://www.vvs.de/datenschutz" target="_blank" rel="noreferrer">Datenschutz</a>
                </footer>
              </React.Fragment>
            )}
          </TransformWrapper>
        </div>
      </div>
    )
  };

Map.propTypes = {};

Map.defaultProps = {};

export default Map;
